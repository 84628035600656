@import "./Colors.scss";

.carousel {
    /*
    img {
        height: 400px;
        margin-left: 50%;
        transform: translate(-50%,0);
    }
    */
    position: relative;

    img {
        width: 100%;
    }
    z-index: 9 !important;

    .carousel-indicators {
        z-index: 8 !important;
    }
}


.container-1 {
    padding: 80px;
}

.who-are-we {
    h1 {
        font-weight: normal;
        font-size: 40px;
    }

    P {
        font-size: 18px;
        color: grey;
        line-height: 1.5;
    }
  

    .col-sm-7 {
        padding: 80px;
    }

    .col-sm-5 {
        background-image: url(../../res/carousel/IMG_3448.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.catalog {
    background-color: #f8f9fa;
    h1 {
        font-weight: normal;
        font-size: 40px;
    }
}


.contact {
    h1 {
        font-weight: normal;
        font-size: 40px;
    }

    label {
        font-size: 18px;
        line-height: 1.5;
        color: grey;
    }
}


.footer {
    background-color: #f8f9fa;
    p {
        color: grey;
    }
    

    .bottom {
        padding: 20px;
        background-color: darken($color:#f8f9fa, $amount: 4);
    }

    .body {
        padding: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 80px;
    padding-right: 80px;
    }
    

    p {
        font-size: 18px;
        line-height: 1.5;
    }
}

.product-item {
    max-width: 200px;
    border-radius: 5px;
    overflow: hidden;
    background-color: white;

    position: relative;

    .favorite {
        left: 10px;
        bottom: 10px;
        position: absolute;
    }

    .add-to-cart {
        position: absolute;
        right:10px;
        bottom: 10px;
        max-width: 60%;

        input {
            text-align: center;
            padding: 5px;
            min-width: 60px;
        }
    }
    .image {
        width: 200px;
        height: 200px;
    }

    .body {
        background-color: white !important;
        padding-bottom: 60px !important;
    }
}


@media screen and (max-width:800px){

    .who-are-we {
        .col-sm-7 {
            padding: 40px;

            h1 {
                text-align: center;
            }

            p {
                text-align: center;
            }
        }
    }
    .container-1 {
        padding: 40px;
    }

    .footer {
        padding: 40px;
    }
}