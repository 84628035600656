@import "./Animations.scss";
@import "./Boilerpolate.scss";
@import "./Buttons.scss";
@import "./FlexTools.scss";
@import "./Loaders.scss";
@import "./Navbar.scss";
@import "./Sections.scss";
@import "./Colors.scss";

@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

.bp3-overlay {
    z-index: 9999999 !important;
}


.btn {
    outline: none !important;
    box-shadow: none !important;

    &:active {
        outline: none !important;
        box-shadow: none !important; 
    }

    &:focus {
        outline: none !important;
    box-shadow: none !important;
    }
}